import * as React from "react";
import SEO from "../components/SEO";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/Footer";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import TunisiaJobs from "../assets/img/adventure_banner_website.png";
import Microcred from "../assets/img/microcred-card.png";
import OurTourism from "../assets/img/siye7atouna.png";
import Southern from "../assets/img/southern.png";
import Festival from "../assets/img/festival-card.jpeg";
import Upskill from "../assets/img/upskill-card.jpeg";

const Marketing = () => {
  return (
    <>
      <SEO title="Opportunities | Visit Tunisia Project" />
      <Header />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              id="block-assistancetoentreprises"
              className="block block-block-content block-block-contentbbbe56a9-7411-4d30-b6b6-041089750357"
            >
              <div className="intern-banner">
                <div className="overlay">
                  <img src={BannerEffect} alt="" />
                </div>
                <img src={TunisiaJobs} alt="" />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      Opportunities
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Promoting Tunisia as a{" "}
                        <span>
                          {" "}
                          premier tourism destination for adventure, nature, and
                          cultural heritage.{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={48}
                role="article"
                about="/en/Assistance_To_Enterprises"
                className="node node--type-activities node--promoted node--view-mode-full"
              >
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section
                        className="sector-vision"
                        style={{ height: "325px" }}
                      >
                        <div className="container">
                          <h2
                            class="title-left aos-animate aos-init"
                            data-aos="fade-up"
                          >
                            <div
                              class="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item"
                              style={{ marginTop: "5%" }}
                            >
                              USAID Visit Tunisia Opportunities
                            </div>
                          </h2>
                          <div
                            class="section-paragh aos-init aos-animate"
                            data-aos="fade-up"
                            style={{ marginTop: "2%", fontSize: "1.2rem" }}
                          >
                            <p>
                              <strong>USAID Visit Tunisia</strong> provides
                              resources for <strong>companies</strong> and{" "}
                              <strong>entrepreneurs</strong>,{" "}
                              <strong>culture bearers</strong>,{" "}
                              <strong>festivals</strong>,
                              <strong>
                                destination management organizations
                              </strong>
                              , <strong>hotels</strong>,{" "}
                              <strong>
                                associations and federations operating in
                                tourism to develop
                              </strong>
                              ,<strong>grow</strong>, and{" "}
                              <strong>expand their market</strong> locally and
                              internationally and{" "}
                              <strong>improve their products</strong>,{" "}
                              <strong>service and experiences</strong> and{" "}
                              <strong>thus</strong> generate sustainable jobs
                              and increase tourism spending and revenues.
                            </p>
                            <p>
                              We support companies and vital elements of the
                              tourism sector through various means, including{" "}
                              <strong>
                                marketing campaigns, technical assistance,
                                one-on-one coaching, mentorship, employee
                                trainings, workshops,
                              </strong>{" "}
                              as well as
                              <strong>
                                grant support helping companies access finance,
                                create tourism products and experiences,
                                identify new markets, improving the quality of
                                their products, training employees,
                              </strong>
                              to name a few.{" "}
                              <strong>Supporting women and youth</strong> as
                              well expanding opportunities to underserved areas
                              are important cross-cutting themes of our work.
                            </p>
                          </div>
                        </div>
                      </section>
                      <section className="resources" id="grants">
                        <div className="container">
                          <div
                            className="opportunities-section"
                            style={{ marginTop: "25px", height: "60%" }}
                          >
                            <p style={{ color: "#cf0936", fontSize: "20px" }}>
                              <strong>Apply for current opportunities!</strong>
                            </p>
                            <div className="d-flex flex-wrap">
                              <div
                                className="item work-card aos-animate"
                                style={{
                                  position: "relative",
                                }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                                onClick={() =>
                                  window.open(
                                    "https://docs.google.com/forms/d/e/1FAIpQLScTQSN55l9z74byIdgexOoXah764qb8FxBDjDRIGla9TmvC1w/viewform",
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  src={OurTourism}
                                  alt="siye7etouna"
                                  style={{ position: "unset" }}
                                  className="hover-image-opportunities"
                                />
                                <div className="card-text">
                                  <h3>
                                    CALL FOR MICRO AND VERY SMALL ENTERPRISE
                                  </h3>
                                  <span className="subheader">
                                    Get a loan up to TND 40 thousand, a grant of
                                    TND 800, and a marketing coaching!
                                  </span>
                                  <div class="card-info justify-content-between align-items-center">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                      }}
                                    >
                                      <a
                                        style={{
                                          width: "40%",
                                          borderRadius: "26px",
                                          color: "white",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        href="https://docs.google.com/forms/d/e/1FAIpQLScTQSN55l9z74byIdgexOoXah764qb8FxBDjDRIGla9TmvC1w/viewform"
                                        target="_blank"
                                        className="btn aos-animate"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-back"
                                      >
                                        APPLY NOW
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="container">
                          <div
                            className="opportunities-section"
                            style={{ marginTop: "25px", height: "60%" }}
                          >
                            <p style={{ color: "#cf0936", fontSize: "20px" }}>
                              <strong>
                                Stay tuned for upcoming opportunities!
                              </strong>
                            </p>
                            <div className="d-flex flex-wrap">
                              <div
                                className="item work-card aos-animate"
                                style={{
                                  position: "relative",
                                  backgroundColor: "#002F6C",
                                }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                              >
                                <div className="hover-image-opportunities" />
                                <div className="card-text">
                                  <h3 style={{ textTransform: "uppercase" }}>
                                    CALL FOR TOURISM BUSINESSES
                                  </h3>
                                  <span className="subheader">
                                    Receive a grant and technical assistance on
                                    product development and marketing and
                                    accelerate your business development!
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="container">
                          <div
                            className="opportunities-section"
                            style={{ marginTop: "25px", height: "60%" }}
                          >
                            <p style={{ color: "#cf0936", fontSize: "20px" }}>
                              <strong>Past opportunities</strong>
                            </p>
                            <div className="d-flex flex-wrap">
                              <div
                                className="item work-card aos-animate"
                                style={{
                                  position: "relative",
                                }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                                onClick={() =>
                                  window.open(
                                    "https://southerncampaign.visittunisiaproject.org/fr",
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  src={Southern}
                                  alt="adventure-call"
                                  className="hover-image-opportunities"
                                />
                                <div className="card-text">
                                  <h3>
                                    CALL FOR TOURISM SMEs OPERATING IN THE SOUTH{" "}
                                  </h3>
                                  <span className="subheader">
                                    Get featured in a marketing campaign and
                                    grow your business!
                                  </span>
                                  <div class="card-info justify-content-between align-items-center">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <a
                                        style={{
                                          width: "40%",
                                          borderRadius: "26px",
                                          color: "white",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        href="https://southerncampaign.visittunisiaproject.org/fr"
                                        target="_blank"
                                        className="btn aos-animate"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-back"
                                      >
                                        APPLY NOW
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate"
                                style={{
                                  position: "relative",
                                  backgroundColor: "#A7C6ED",
                                }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                                onClick={() =>
                                  window.open(
                                    "https://if.visittunisiaproject.org/",
                                    "_blank"
                                  )
                                }
                              >
                                <div />
                                <div className="card-text">
                                  <h3>CALL FOR CAPITAL-SEEKERS & INVESTORS</h3>
                                  <span className="subheader">
                                    Receive investment facilitation support and
                                    raise your capital!
                                  </span>
                                  <div class="card-info justify-content-between align-items-center">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                      }}
                                    >
                                      <a
                                        style={{
                                          width: "40%",
                                          borderRadius: "26px",
                                          color: "white",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        href="https://if.visittunisiaproject.org/"
                                        target="_blank"
                                        className="btn aos-animate"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-back"
                                      >
                                        APPLY NOW
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate"
                                style={{ position: "relative" }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                                onClick={() =>
                                  window.open(
                                    "https://forms.gle/xuHyny1KLfh3GJoU6",
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  src={Microcred}
                                  alt="adventure-call"
                                  className="hover-image-opportunities"
                                />
                                <div className="card-text">
                                  <h3>
                                    CALL FOR MICRO AND VERY SMALL ENTERPRISES
                                  </h3>
                                  <span className="subheader">
                                    Get a loan of up to 40 thousand dinars & up
                                    to 1,250 dinars grant! 
                                  </span>
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  window.open(
                                    "https://adventures.visittunisiaproject.org/en",
                                    "_blank"
                                  )
                                }
                                className="item work-card aos-animate"
                                style={{ position: "relative" }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                              >
                                <img
                                  src={TunisiaJobs}
                                  alt="adventure-call"
                                  className="hover-image-opportunities"
                                />
                                <div className="card-text">
                                  <h3>
                                    CALL FOR NATURE & ADVENTURE TOURISM SMEs
                                  </h3>
                                  <span className="subheader">
                                    Get featured in a nationwide marketing
                                    campaign and grow your business!
                                  </span>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate"
                                style={{
                                  position: "relative",
                                }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                                onClick={() =>
                                  window.open(
                                    "https://upskill.visittunisiaproject.org/en",
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  src={Upskill}
                                  alt="adventure-call"
                                  className="hover-image-opportunities"
                                />
                                <div className="card-text">
                                  <h3 style={{ textTransform: "uppercase" }}>
                                    CALL FOR TOURISM ASSCIATIONS & FEDERATIONS
                                  </h3>
                                  <span className="subheader">
                                    Receive capacity-building training,
                                    mentorship and grants and take your
                                    federation/association to the next level!
                                  </span>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate"
                                style={{
                                  position: "relative",
                                }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                                onClick={() =>
                                  window.open(
                                    "https://festivals.visittunisiaproject.org/",
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  src={Festival}
                                  alt="adventure-call"
                                  className="hover-image-opportunities"
                                />
                                <div className="card-text">
                                  <h3 style={{ textTransform: "uppercase" }}>
                                    CALL FOR TUNISIAN FESTIVALS
                                  </h3>
                                  <span className="subheader">
                                    Join the Festival Incubator program and
                                    receive technical assistance and mentorship
                                    to better design, organize, and market your
                                    festival!
                                  </span>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate"
                                style={{
                                  position: "relative",
                                  backgroundColor: "#BA0C2F",
                                }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                                onClick={() =>
                                  window.open(
                                    "https://amp.visittunisiaproject.org/en",
                                    "_blank"
                                  )
                                }
                              >
                                <div
                                  style={{
                                    backgroundColor: "#BA0C2F !important",
                                  }}
                                  className="hover-image-opportunities"
                                />
                                <div className="card-text">
                                  <h3 style={{ textTransform: "uppercase" }}>
                                    CALL FOR GRANT AND ASSISTANCE FOR BUSINESSES
                                  </h3>
                                  <span className="subheader">
                                    Receive a grant and technical assistance on
                                    product development and marketing and
                                    accelerate your business development!
                                  </span>
                                </div>
                              </div>

                              <div
                                className="item work-card aos-animate"
                                style={{
                                  position: "relative",
                                  backgroundColor: "#6C6463",
                                }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                                onClick={() =>
                                  window.open(
                                    "https://sme-app.visittunisiaproject.org/en",
                                    "_blank"
                                  )
                                }
                              >
                                <div className="hover-image-opportunities" />
                                <div className="card-text">
                                  <h3 style={{ textTransform: "uppercase" }}>
                                    CALL FOR TRAINING & ASSISTANCE FOR SMEs
                                  </h3>
                                  <span className="subheader">
                                    Gain skills and resources to improve your
                                    business operations and profitability!
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};
export default Marketing;
